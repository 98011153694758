<template>
  <div>
    <b-row class="m-0">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }} <span v-show="archive" class="text-danger">&nbsp;(archive)</span>
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchEventType"
            v-model="searchEventType"
            placeholder="Rechercher un type"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="popupCreateEventType('')"
            class="px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            Nouveau 
          </b-button>
          <vs-dropdown class="cursor-pointer">
            <div>
              <b-button
                variant="outline-primary"
                class="px-1"
              >
                  <feather-icon icon="SettingsIcon" />
                  <!-- Paramètres -->
              </b-button>
            </div>
            <vs-dropdown-menu class="w-full">
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="archive=!archive">{{ !archive ? 'Voir les types archivés' : 'Voir les types actifs' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="togglePanelColumn">{{this.gridOptions.sideBar == null? 'Gérer les colonnes' : 'Masquer la gestion des colonnes' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingEventTypesList || isLoadingEventTypesListArchived">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        {{archive ? 'Chargement des archives en cours...' : 'Chargement en cours de la liste des eventTypes...'}}
      </div>
      <ag-grid-vue v-else style="width: 100%; height: 100%;"
          class="ag-theme-material"
          :class="archive ? 'ag-archived' : ''"
          :rowData="gridData"
          :gridOptions="gridOptions"
          :key="archive+filter"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked">
      </ag-grid-vue>
    </div>

    <popup-create-event-type ref="popupCreateEventType"></popup-create-event-type>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import { agGridLocaleFR } from "@/data/agGridLocaleFR"

import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'

import CellRendererColors from '@/components/planning/planning-settings/CellRendererColors'
import PopupCreateEventType from '@/components/planning/planning-settings/popup-planning-settings/PopupCreateEventType';


export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,
    AgGridVue,
    CellRendererColors,
    PopupCreateEventType
  },
  directives: {
    Ripple
  },
  data () {
    return {
      archive:false,
      filter: false,
      sidebar:false,
      activeSidebarEventType:false,
      gridOptions:{
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination:true,
        paginationPageSize:20,
      },
      gridApi: null,
      columnApi: null,
      searchEventType: ''
    }
  },
  computed: {
    ...mapGetters([
      'eventTypesList',
      'eventTypesListArchived',
      'isLoadingEventTypesList',
      'isLoadingEventTypesListArchived',
      'isOpenSidebarEventType',
    ]),
    gridData () {
      if(this.archive){
        return this.$store.getters.eventTypesListArchived
      }else{
        return this.$store.getters.eventTypesList
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('fetchEventTypesList');
    this.gridOptions.columnDefs = [      
      { field: 'color', headerName: 'Couleur', resizable:true,  sortable: true, maxWidth: 120, cellRenderer: 'CellRendererColors' },
      { field: 'label', headerName: 'Nom', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
      { field: 'description', headerName: 'Description', resizable:true, sortable: true},
      { field: 'isDependent', headerName: 'Indépendant', initialHide:true, resizable:true, sortable: true, maxWidth: 120, headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, cellRenderer: 
        params => { return params.value == true ? 'Oui' : 'Non'  }
      },
      { field: 'isEditable', headerName: 'Editable', initialHide:true, sortable: true, resizable:true, maxWidth: 120, headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, cellRenderer: 
        params => { return params.value == true ? 'Oui' : 'Non'  }
      },
    ];
  },
  methods: {
    ...mapActions([ 
      'fetchEventTypesList',
      'fetchEventTypesListArchived',
      'deleteEventType'
    ]),

    // Gestion d'AgGrid
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event){
      this.popupCreateEventType(event.data.id)
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.searchEventType
      );
    },
    togglePanelColumn(){
      if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Colonnes',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }
        this.filter=true;
      }else{
        this.gridOptions.sideBar= null
        this.filter=false;
      }
    },
    exportDataXLS(){
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV(){
      this.gridApi.exportDataAsCsv();
    },
    popupCreateEventType(id){
      this.$bvModal.show('modal-create-event-type')
      this.$refs['popupCreateEventType'].popupCreateEventTypeEvent(id)
    }
  }
}
</script>
