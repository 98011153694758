<template>
<b-modal id="modal-create-event-type" ref="modal-create-event-type" centered="centered" :title="(eventType.id ? 'Modifier': 'Ajouter') + ` un type d'événement`" @ok="create">
    <div v-if="isCreatingEventType">
        <div class="text-center">
            <div class="spinner-border text-primary" role="status"><span class="sr-only">Chargement...</span></div><br />Chargement des détails...
        </div>
    </div>
    <validation-observer v-else ref="formCreateEventType">

        <b-row>
            <b-col cols="12" class="d-flex mb-1">
                <b-form-group label="Libellé *" label-for="Libellé" class="pr-1" style="flex:1">
                    <validation-provider #default="{ errors }" name="Libellé" rules="required"> 
                    <b-form-input
                        id="socialReason"
                        v-model="eventType.label"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Couleur" label-for="color" class="d-flex flex-column align-content-center align-items-center">
                    <validation-provider #default="{ errors }" name="Color">
                    <v-swatches
                        :state="errors.length > 0 ? false : null" 
                        v-model="eventType.color"
                        show-fallback
                        popover-x="left"
                        id="color"
                        :show-border="false"
                        :swatch-size="32"
                        :trigger-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                        :swatch-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                        :wrapper-style="{ paddingLeft: '8px', paddingRight: '0px' }"
                    ></v-swatches> 
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group label="Description" label-for="description">
                    <validation-provider #default="{ errors }" name="Description">
                        <b-form-textarea id="description" v-model="eventType.description" :state="errors.length &gt; 0 ? false : null" rows="3"></b-form-textarea><small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group label-for="isDependent"><span>Ce type d&apos;évènement est-il dépendant d'une affaire?</span>
                    <b-form-checkbox class="float-right mr-0" id="isDependent" checked="false" v-model="eventType.isDependent" switch="switch" inline="inline"></b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label-for="isEditable" class="w-100">
                  <span>Ce type d&apos;évènement est-il modifiable par un tiers ?</span>
                    <b-form-checkbox class="float-right mr-0" id="isEditable" checked="false" v-model="eventType.isEditable" switch="switch" inline="inline"></b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
    </validation-observer><template #modal-footer="{ ok, cancel }">
        <div class="w-100">
            <b-button class="float-left" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="eventType.id" variant="outline-danger" @click="ok()">Supprimer</b-button>
            <b-button class="float-right" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="ok()">{{eventType.id ? 'Modifier': 'Ajouter'}}</b-button>
            <b-button class="float-right mr-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="cancel()">Annuler</b-button>
        </div>
    </template>
</b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import VSwatches from "vue-swatches";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      eventType: {
        label: "",
        color: "#0c3571",
        isDependent: false,
        isEditable: false,
        description: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      },
    };
  },
  methods: {
    popupCreateEventTypeEvent(id){
      if(id>0){
        this.fetchEventType(id).then(res => {
          this.eventType = res
        })
      }else this.initializeForm()
    },
    initializeForm() {
      this.eventType = {
        label: "",
        color: "#0c3571",
        isDependent: false,
        isEditable: false,
        description: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreateEventType.validate().then((success1) => {
        if (success1) {
          if(this.eventType.id) this.updateEventType(this.eventType)
          else this.createEventType(this.eventType);
          this.$nextTick(() => {
            this.$refs["modal-create-event-type"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createEventType", "updateEventType", "fetchEventType"]),
  },
  computed: {
    ...mapGetters(["isCreatingEventType"]),
  },
  
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
</style>