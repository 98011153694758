<template>
    <span class="chip">
        <span class="point big" :style="'background-color:'+item"></span>
    </span>
    <!-- <span>
        <feather-icon
          v-b-tooltip.hover
          icon="ClockIcon"
          size="21"
          :class="statusColor(data.item.status)"
          :title="statusTitle(data.item.status)"
        />
        <feather-icon
          v-if="
            new Date(data.item.endDate) < new Date() && data.item.status == 0
          "
          v-b-tooltip.hover
          icon="AlertCircleIcon"
          size="21"
          class="text-danger"
          :title="'Date de fin dépassée'"
        />
        <feather-icon
          v-if="
            new Date(data.item.startDate) > new Date() && data.item.status == 0
          "
          v-b-tooltip.hover
          icon="AlertCircleIcon"
          size="21"
          class="text-danger"
          :title="'Date de début pas encore atteinte'"
        />
    </span> -->
</template>
<script>
export default {

  data () {
    return {
      item: ''
    }
   },
   beforeMount() {
       this.item = this.params.value;
   }
};
</script>